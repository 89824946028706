import React from "react";

function Homepagecomponent() {
  return (
    <>
      <section
        id="hero"
        className="inflanar-hero inflanar-bg-cover p-relative inflanar-ohidden"
      >
        <div className="inflanar-hero-shape">
          <img alt="#" src="assets/img/in-hero-shape.svg" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inflanar-hero__inside">
                <div className="inflanar-hero__inner">
                  <div
                    className="inflanar-hero__content"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <span className="inflanar-hero__sub inflanar-regular-font">
                      GO TO YOUR INFLUENCER PLATFROM
                    </span>
                    <h1 className="inflanar-hero__title">
                      The Right <span>Influencer </span> For Your Business
                    </h1>
                  </div>

                  <div
                    className="inflanar-ptags"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <span className="inflanar-ptags__title">
                      Popular Search
                    </span>
                    <ul className="inflanar-ptags__list list-none">
                      <li>
                        <a href="services54af.html?search=TikTok">TikTok</a>
                      </li>
                      <li>
                        <a href="servicesb165.html?search=Facebook">Facebook</a>
                      </li>
                      <li>
                        <a href="services65ca.html?search=Instagram">
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a href="servicesb04a.html?search=Linkedin">Linkedin</a>
                      </li>
                      <li>
                        <a href="services2237.html?search=Influencer">
                          Influencer
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="inflanar-sclient"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="inflanar-sclient__img">
                      <img
                        alt="#"
                        src="assets/uploads/website-images/slider-client-20230904102359.png"
                      />
                    </div>
                    <h4 className="inflanar-sclient__title">
                      <b className="in-counter">30</b>+{" "}
                      <span>Satisfied Clients</span>
                    </h4>
                  </div>
                </div>
                <div
                  className="inflanar-hero__img"
                  data-aos="fade-left"
                  data-aos-delay="700"
                >
                  <img
                    alt="#"
                    src="assets/uploads/website-images/slider-one-feature-20230904102503.png"
                  />
                  <div className="inflanar-hero-social inflanar-hero-social--1 inflanar-anim-shape1">
                    <img alt="#" src="assets/img/in-gsocial1.svg" />
                  </div>
                  <div className="inflanar-hero-social inflanar-hero-social--2 inflanar-anim-shape2">
                    <img alt="#" src="assets/img/in-gsocial2.svg" />
                  </div>
                  <div className="inflanar-hero-social inflanar-hero-social--3 inflanar-anim-shape3">
                    <img alt="#" src="assets/img/in-gsocial3.svg" />
                  </div>
                  <div className="inflanar-hero-social inflanar-hero-social--4 inflanar-anim-shape4">
                    <img alt="#" src="assets/img/in-gsocial4.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pd-top-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inflanar-features-list inflanar-section-shape14 inflanar-bg-cover">
                <div className="row">
                  <div className="col-12">
                    <div className="inflanar-section__head inflanar-section__center mg-btm-20">
                      <span
                        className="inflanar-section__badge inflanar-primary-color m-0"
                        data-aos="fade-in"
                        data-aos-delay="300"
                      >
                        <span>Best Feature</span>{" "}
                        <img alt="#" src="assets/img/in-section-vector.svg" />
                      </span>
                      <h2
                        className="inflanar-section__title"
                        data-aos="fade-in"
                        data-aos-delay="400"
                      >
                        Our Latest Features
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="row inflanar-features-gap">
                  <div
                    className="col-lg-3 col-md-6 col-6 mg-top-30"
                    data-aos="fade-in"
                    data-aos-delay="400"
                  >
                    <div className="inflanar-features-list__single">
                      <div className="inflanar-features-list__head">
                        <div className="inflanar-features-list__first">
                          <div className="inflanar-features-list__icon ">
                            <img
                              alt="#"
                              src="assets/uploads/website-images/our-feature1-2023-10-16-02-13-11-6279.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inflanar-features-list__body">
                        <h4 className="inflanar-features-list__title">
                          No upfront Cost
                        </h4>
                        <p className="inflanar-features-list__text">
                          Our platform ensures a seamless connection between
                          brands and influencers, eliminating any upfront costs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-6 mg-top-30"
                    data-aos="fade-in"
                    data-aos-delay="600"
                  >
                    <div className="inflanar-features-list__single">
                      <div className="inflanar-features-list__head">
                        <div className="inflanar-features-list__first">
                          <div className="inflanar-features-list__icon inflanar-scolor-bg">
                            <img
                              alt="#"
                              src="assets/uploads/website-images/our-feature2-2023-10-16-02-13-35-2109.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inflanar-features-list__body">
                        <h4 className="inflanar-features-list__title">
                          Verified Creators
                        </h4>
                        <p className="inflanar-features-list__text">
                          Our network showcases only verified creators, ensuring
                          authenticity and reliability. Connect with influencers
                          for creating a genuine and impactful partnership.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-6 mg-top-30"
                    data-aos="fade-in"
                    data-aos-delay="800"
                  >
                    <div className="inflanar-features-list__single">
                      <div className="inflanar-features-list__head">
                        <div className="inflanar-features-list__first">
                          <div className="inflanar-features-list__icon inflanar-tcolor-bg">
                            <img
                              alt="#"
                              src="assets/uploads/website-images/our-feature3-2023-10-16-02-14-45-9471.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inflanar-features-list__body">
                        <h4 className="inflanar-features-list__title">
                          Influencer Analytics
                        </h4>
                        <p className="inflanar-features-list__text">
                          Dive into comprehensive analytics to understand
                          influencer performance, audience demographics, and
                          engagement metrics. Make informed choices and maximize
                          the impact of your brand collaborations.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-6 col-6 mg-top-30"
                    data-aos="fade-in"
                    data-aos-delay="1000"
                  >
                    <div className="inflanar-features-list__single">
                      <div className="inflanar-features-list__head">
                        <div className="inflanar-features-list__first">
                          <div className="inflanar-features-list__icon inflanar-ylcolor-bg">
                            <img
                              alt="#"
                              src="assets/uploads/website-images/our-feature3-2023-10-16-02-14-59-7866.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inflanar-features-list__body">
                        <h4 className="inflanar-features-list__title">
                          Safe Camping
                        </h4>
                        <p className="inflanar-features-list__text">
                          Our platform prioritizes safety, providing a
                          trustworthy space for influencers and brands to camp
                          together. Safe camping, successful partnerships.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="pd-top-120 pd-btm-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inflanar-section__head inflanar-section__center mg-btm-20">
                <span
                  className="inflanar-section__badge inflanar-primary-color m-0"
                  data-aos="fade-in"
                  data-aos-delay="300"
                >
                  <span>Top Influencer</span>{" "}
                  <img alt="#" src="assets/img/in-section-vector.svg" />
                </span>
                <h2
                  className="inflanar-section__title"
                  data-aos="fade-in"
                  data-aos-delay="400"
                >
                  Talented Influencer Member
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="inflanar-influencer">
                <div className="inflanar-influencer__head">
                  <img
                    alt="#"
                    src="assets/uploads/custom-images/alvantan-khan-2023-10-29-01-46-37-2249.png"
                  />
                  <h4 className="inflanar-influencer__title">
                    <a href="influencers/alvantan-khan-20231018013139.html">
                      Alvantan Khan<span>Sport &amp; Fitness</span>
                    </a>
                  </h4>
                </div>
                <div className="inflanar-influencer__body">
                  <div className="inflanar-influencer__follower">
                    <div className="inflanar-influencer__follower--single">
                      <b>1250K</b>
                      <span>Followers</span>
                    </div>
                    <div className="inflancer-hborder"></div>
                    <div className="inflanar-influencer__follower--single in-right">
                      <b>12K</b>
                      <span>Following</span>
                    </div>
                  </div>
                  <a
                    className="inflanar-btn-full  mg-top-20"
                    href="influencers/alvantan-khan-20231018013139.html"
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="inflanar-influencer">
                <div className="inflanar-influencer__head">
                  <img
                    alt="#"
                    src="assets/uploads/custom-images/alvantan-khan-2023-10-18-12-07-55-8858.png"
                  />
                  <h4 className="inflanar-influencer__title">
                    <a href="influencers/alvantan-khan-20231018120534.html">
                      Nasrun Nessa<span>Sport &amp; Fitness</span>
                    </a>
                  </h4>
                </div>
                <div className="inflanar-influencer__body">
                  <div className="inflanar-influencer__follower">
                    <div className="inflanar-influencer__follower--single">
                      <b>8450K</b>
                      <span>Followers</span>
                    </div>
                    <div className="inflancer-hborder"></div>
                    <div className="inflanar-influencer__follower--single in-right">
                      <b>75K</b>
                      <span>Following</span>
                    </div>
                  </div>
                  <a
                    className="inflanar-btn-full  mg-top-20"
                    href="influencers/alvantan-khan-20231018120534.html"
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="inflanar-influencer">
                <div className="inflanar-influencer__head">
                  <img
                    alt="#"
                    src="assets/uploads/custom-images/nawyantong-2023-10-17-11-54-32-5597.png"
                  />
                  <h4 className="inflanar-influencer__title">
                    <a href="influencers/nawyantong-20231017040916.html">
                      Nawyantong<span>Graphic Designer</span>
                    </a>
                  </h4>
                </div>
                <div className="inflanar-influencer__body">
                  <div className="inflanar-influencer__follower">
                    <div className="inflanar-influencer__follower--single">
                      <b>2550K</b>
                      <span>Followers</span>
                    </div>
                    <div className="inflancer-hborder"></div>
                    <div className="inflanar-influencer__follower--single in-right">
                      <b>84K</b>
                      <span>Following</span>
                    </div>
                  </div>
                  <a
                    className="inflanar-btn-full  mg-top-20"
                    href="influencers/nawyantong-20231017040916.html"
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="inflanar-influencer">
                <div className="inflanar-influencer__head">
                  <img
                    alt="#"
                    src="assets/uploads/custom-images/david-richard-2023-10-16-03-32-15-6862.png"
                  />
                  <h4 className="inflanar-influencer__title">
                    <a href="influencers/david-richard-20231016032602.html">
                      David Richard<span>Digital Marketer</span>
                    </a>
                  </h4>
                </div>
                <div className="inflanar-influencer__body">
                  <div className="inflanar-influencer__follower">
                    <div className="inflanar-influencer__follower--single">
                      <b>1250K</b>
                      <span>Followers</span>
                    </div>
                    <div className="inflancer-hborder"></div>
                    <div className="inflanar-influencer__follower--single in-right">
                      <b>12K</b>
                      <span>Following</span>
                    </div>
                  </div>
                  <a
                    className="inflanar-btn-full  mg-top-20"
                    href="influencers/david-richard-20231016032602.html"
                  >
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row mg-top-40"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div className="col-12 d-flex justify-content-center">
              <a
                href="influencers.html"
                className="inflanar-btn inflanar-btn__big"
              >
                <span>View All</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="pd-top-120 pd-btm-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="inflanar-section__head inflanar-section__center text-center mg-btm-20">
                <span
                  className="inflanar-section__badge  inflanar-primary-color m-0"
                  data-aos="fade-in"
                  data-aos-delay="300"
                >
                  <span>Working Process</span>{" "}
                  <img alt="#" src="assets/img/in-section-vector.svg" />
                </span>
                <h2
                  className="inflanar-section__title"
                  data-aos="fade-in"
                  data-aos-delay="400"
                >
                  How Dose It Work?
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="inflanar-hcard inflanar-hcard--one">
                <div className="inflanar-hcard__img">
                  <img
                    alt="#"
                    src="assets/uploads/website-images/why_choose_us1-2023-09-05-06-14-59-3945.jpg"
                  />
                </div>
                <div className="inflanar-hcard__content">
                  <div className="inflanar-hcard__line">
                    <img alt="#" src="assets/img/in-line-shape1.svg" />
                  </div>
                  <h4 className="inflanar-hcard__label">
                    <span>Step</span>
                    <b>1</b>
                  </h4>
                  <h4 className="inflanar-hcard__title">Create Account</h4>
                  <p className="inflanar-hcard__text">
                    Begin your journey effortlessly. Sign up and unlock the
                    world of influencer-brand connections in seconds.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="inflanar-hcard inflanar-hcard--two">
                <div className="inflanar-hcard__content inflanar-hcard__content__two">
                  <h4 className="inflanar-hcard__label">
                    <span>Step</span>
                    <b>2</b>
                  </h4>
                  <h4 className="inflanar-hcard__title">Choose Influencer</h4>
                  <p className="inflanar-hcard__text">
                    Explore a diverse pool of influencers and brands, find your
                    match, and kickstart impactful collaborations.
                  </p>
                  <div className="inflanar-hcard__line">
                    <img alt="#" src="assets/img/in-line-shape2.svg" />
                  </div>
                </div>
                <div className="inflanar-hcard__img">
                  <img
                    alt="#"
                    src="assets/uploads/website-images/why_choose_us2-2023-09-05-06-15-48-2251.jpg"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="inflanar-hcard inflanar-hcard--one">
                <div className="inflanar-hcard__img">
                  <img
                    alt="#"
                    src="assets/uploads/website-images/why_choose_us3-2023-09-05-06-16-49-3923.jpg"
                  />
                </div>
                <div className="inflanar-hcard__content">
                  <div className="inflanar-hcard__line inflanar-hcard__line--v2">
                    <img alt="#" src="assets/img/in-line-shape3.svg" />
                  </div>
                  <h4 className="inflanar-hcard__label">
                    <span>Step</span>
                    <b>3</b>
                  </h4>
                  <h4 className="inflanar-hcard__title">
                    Monitor Your Campaign
                  </h4>
                  <p className="inflanar-hcard__text">
                    Take charge as your campaign unfolds. Stay connected in
                    real-time for a successful influencer partnership.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              <div className="inflanar-hcard inflanar-hcard--two">
                <div className="inflanar-hcard__content inflanar-hcard__content__two">
                  <h4 className="inflanar-hcard__label">
                    <span>Step</span>
                    <b>4</b>
                  </h4>
                  <h4 className="inflanar-hcard__title">Check Your Report</h4>
                  <p className="inflanar-hcard__text">
                    Dive into detailed reports. Gain insights and refine your
                    strategy for future influencer collaborations.
                  </p>
                  <div className="inflanar-hcard__line inflanar-hcard__line--v3">
                    <img alt="#" src="assets/img/in-line-shape2.svg" />
                  </div>
                </div>
                <div className="inflanar-hcard__img">
                  <img
                    alt="#"
                    src="assets/uploads/website-images/why_choose_us3-2023-09-05-06-17-45-6525.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="video-cta inflanar-section-shape3 inflanar-ohidden inflanar-bg-cover pd-top-90 pd-btm-120 inflanar-section-shape2">
        <div className="container inflanar-container-medium">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="video-cta__content">
                <h3 className="video-cta__title mg-btm-20">
                  Manage all your creator and brand relationships in one place.
                </h3>
                <p className="video-cta__text">
                  Streamline and centralize all your creator and brand
                  relationships effortlessly. Manage, nurture, and thrive in one
                  dedicated space for seamless influencer connections. Elevate
                  your brand partnerships with ease.
                </p>

                <a href="about-us.html" className="inflanar-btn mg-top-40">
                  <span>Discover More</span>
                </a>
              </div>
            </div>
            <div
              className="col-xl-5 offset-xl-1 col-lg-6 col-md-6 col-12 mg-top-30"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="video-cta__card">
                <img
                  alt="#"
                  src="assets/uploads/website-images/video-image-2023-09-06-06-30-36-3964.webp"
                />
                <div className="video-cta__button">
                  <a
                    href="index.html"
                    className="js-video-btn"
                    data-video-id="oRhtJTFB5gQ"
                  >
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pd-top-90 pd-btm-120 ">
        <div className="container inflanar-container-medium">
          <div className="row ">
            <div className="col-12">
              <div className="funfacts inflanar-row-gap">
                <div
                  className="funfacts__box inflanar-section-shape5 inflanar-bg-cover"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="funfacts__column">
                    <div className="funfacts__card mg-top-30">
                      <div className="funfacts__icon">
                        <img
                          alt="#"
                          src="assets/uploads/website-images/facebook-icon-2023-10-16-03-05-09-5457.png"
                        />
                      </div>
                      <div className="funfacts__number">
                        <h4 className="funfacts__title">
                          <b>6M+ </b>
                          <span>Followers</span>
                        </h4>
                      </div>
                    </div>
                    <div className="funfacts__card mg-top-30">
                      <div className="funfacts__icon">
                        <img
                          alt="#"
                          src="assets/uploads/website-images/twitter-icon-2023-09-06-07-06-56-7024.svg"
                        />
                      </div>
                      <div className="funfacts__number">
                        <h4 className="funfacts__title">
                          <b>3M+ </b>
                          <span>Followers</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="funfacts__column funfacts__column__last">
                    <div className="funfacts__card mg-top-30">
                      <div className="funfacts__icon">
                        <img
                          alt="#"
                          src="assets/uploads/website-images/twitter-icon-2023-09-06-07-08-23-2473.svg"
                        />
                      </div>
                      <div className="funfacts__number">
                        <h4 className="funfacts__title">
                          <b>2K+ </b>
                          <span>Followers</span>
                        </h4>
                      </div>
                    </div>
                    <div className="funfacts__card mg-top-30">
                      <div className="funfacts__icon">
                        <img
                          alt="#"
                          src="assets/uploads/website-images/twitter-icon-2023-09-06-07-09-35-8675.svg"
                        />
                      </div>
                      <div className="funfacts__number">
                        <h4 className="funfacts__title">
                          <b>10M+ </b>
                          <span>Followers</span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="brands" data-aos="fade-up" data-aos-delay="400">
                  <h2 className="inflanar-section__title mg-btm-20">
                    We Work with Brands that Influence{" "}
                    <span> 30+ Clients </span>
                  </h2>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-02-58-50-2560.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-02-59-11-3955.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-02-59-57-3155.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-00-18-7987.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-00-49-2205.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-01-09-9809.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-01-37-4721.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-01-52-9598.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6 mg-top-30">
                      <div className="brands__single">
                        <a href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/custom-images/our-partner-2023-10-16-03-02-07-4470.svg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
{/* Brand Pricing */}
      <section class="pricing py-5" style={{marginTop:25}} >
        <h2
          className="inflanar-section__title mg-btm-20"
          style={{ textAlign: "center"}}
        >
          Brand Pricing
        </h2>
        <div class="container">
          <div class="row">
            {/* <!-- Free Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Free
                  </h5>
                  <h6 class="card-price text-center">
                    ₹0<span class="period">/month</span>
                  </h6> 
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Basic Influencers Search
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                     Basic Analytics
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      10% Per Transaction
                    </li>
                    {/* <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li> */}
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
					 Influencer Analytics
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Campaign Management
                    </li>
					<li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Connect with Influencer
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                   Access to Feeds
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Plus Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Plus
                  </h5>
                  <h6 class="card-price text-center">
                    ₹5,000<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Professional Influencers Search</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Pro Influencer Analytics
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Campaigns Management
                    </li>
					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Connect with Influencer
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                     Access to Feeds
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Access to 10 Featured Influencers
                    </li>
					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Access to Reports
                    </li>
					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      5% Per Transaction
                    </li>
                   
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Advanced Influencer Search
                    </li>
					<li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Advanced Influencer Analytics
                    </li>
					<li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Advanced Campaigns Management
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Pro Tier --> */}
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Pro
                  </h5>
                  <h6 class="card-price text-center">
                    ₹10,000<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
				  <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Advanced Influencers Search</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Advanced Influencer Analytics
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Advanced Campaigns Management
                    </li>
					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Connect with Influencer
                    </li>
					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Campaigns Analytics
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                     Access to Feeds
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Access to 25 Featured Influencers
                    </li>
                   
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Access to Reports
                    </li>

					<li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      5% Per Transaction
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

{/* Influencer Pricing */}

	  <section class="pricing py-5" >
        <h2
          className="inflanar-section__title mg-btm-20"
          style={{ textAlign: "center"}}
        >
          Influencer Pricing
        </h2>
        <div class="container">
          <div class="row">
            {/* <!-- Free Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Free
                  </h5>
                  <h6 class="card-price text-center">
                    ₹0<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Single User
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      5GB Storage
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Free Subdomain
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Plus Tier --> */}
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Plus
                  </h5>
                  <h6 class="card-price text-center">
                    ₹9<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>5 Users</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      50GB Storage
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Free Subdomain
                    </li>
                    <li class="text-muted">
                      <span class="fa-li">
                        <i class="fas fa-times"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Pro Tier --> */}
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">
                    Pro
                  </h5>
                  <h6 class="card-price text-center">
                    ₹49<span class="period">/month</span>
                  </h6>
                  <hr />
                  <ul class="fa-ul">
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Unlimited Users</strong>
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      150GB Storage
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Public Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Community Access
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Unlimited Private Projects
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Dedicated Phone Support
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      <strong>Unlimited</strong> Free Subdomains
                    </li>
                    <li>
                      <span class="fa-li">
                        <i class="fas fa-check"></i>
                      </span>
                      Monthly Status Reports
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a href="/" class="btn btn-primary text-uppercase">
                      Button
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="inflanar-bg-cover pd-top-90 pd-btm-120 inflanar-section-shape2  inflanar-ohidden">
        <div className="container inflanar-container-medium">
          <div className="row inflanar-container-medium__row align-items-center">
            <div className="col-lg-5 col-12 mg-top-30">
              <div className="inflanar-section__head mg-btm-50">
                <span
                  className="inflanar-section__badge inflanar-primary-color m-0"
                  data-aos="fade-in"
                  data-aos-delay="300"
                >
                  <span>Our Faq’s</span>{" "}
                  <img alt="#" src="assets/img/in-section-vector.svg" />
                </span>
                <h2
                  className="inflanar-section__title mg-btm-20"
                  data-aos="fade-in"
                  data-aos-delay="400"
                >
                  Frequently Faq’s
                </h2>
                <p>
                  Curabitur a pretium orci, a venenatis diam phasell mi velit.
                  Vestibulum et tincidunt.
                </p>
              </div>
              <div
                className="inflanar-support-img"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  alt="#"
                  src="assets/uploads/website-images/faq-image-2023-09-06-06-47-15-3121.webp"
                />
              </div>
            </div>
            <div className="col-lg-7 col-12 mg-top-30">
              <div
                className="inflanar-accordion accordion accordion-flush"
                id="inflanar-accordion"
              >
                <div className="accordion-item inflanar-accordion__single active mg-top-20">
                  <h2 className="accordion-header" id="inflanart-0">
                    <button
                      className="accordion-button collapsed inflanar-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-collapse0"
                    >
                      What is the average number of influencers for the
                      campaign?
                    </button>
                  </h2>
                  <div
                    id="ac-collapse0"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#inflanar-accordion"
                  >
                    <div className="accordion-body inflanar-accordion__body">
                      <p>
                        Vestibulum quis neque nunc. Maecenas pharetra libero id
                        efficitur gravida. Aenean risus enim, coin quam in,
                        consequat nec lacus. Aenean faucibus venenatis aliquet.
                        Sed nulla quam, vehicula ut libe tpat quam. Phasellus
                        semper vitae tellus sit amet scelerisque
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                 */}
                <div className="accordion-item inflanar-accordion__single  mg-top-20">
                  <h2 className="accordion-header" id="inflanart-1">
                    <button
                      className="accordion-button collapsed inflanar-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-collapse1"
                    >
                      Why is influencer marketing critical for social commerce?
                    </button>
                  </h2>
                  <div
                    id="ac-collapse1"
                    className="accordion-collapse collapse "
                    data-bs-parent="#inflanar-accordion"
                  >
                    <div className="accordion-body inflanar-accordion__body">
                      <p>
                        Vestibulum quis neque nunc. Maecenas pharetra libero id
                        efficitur gravida. Aenean risus enim, coin quam in,
                        consequat nec lacus. Aenean faucibus venenatis aliquet.
                        Sed nulla quam, vehicula ut libe tpat quam. Phasellus
                        semper vitae tellus sit amet scelerisque
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                 */}
                <div className="accordion-item inflanar-accordion__single  mg-top-20">
                  <h2 className="accordion-header" id="inflanart-2">
                    <button
                      className="accordion-button collapsed inflanar-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-collapse2"
                    >
                      What is an influencer marketing software?
                    </button>
                  </h2>
                  <div
                    id="ac-collapse2"
                    className="accordion-collapse collapse "
                    data-bs-parent="#inflanar-accordion"
                  >
                    <div className="accordion-body inflanar-accordion__body">
                      <p>
                        Vestibulum quis neque nunc. Maecenas pharetra libero id
                        efficitur gravida. Aenean risus enim, coin quam in,
                        consequat nec lacus. Aenean faucibus venenatis aliquet.
                        Sed nulla quam, vehicula ut libe tpat quam. Phasellus
                        semper vitae tellus sit amet scelerisque
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                 */}
                <div className="accordion-item inflanar-accordion__single  mg-top-20">
                  <h2 className="accordion-header" id="inflanart-3">
                    <button
                      className="accordion-button collapsed inflanar-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-collapse3"
                    >
                      What is an influencer marketing tool?
                    </button>
                  </h2>
                  <div
                    id="ac-collapse3"
                    className="accordion-collapse collapse "
                    data-bs-parent="#inflanar-accordion"
                  >
                    <div className="accordion-body inflanar-accordion__body">
                      <p>
                        Vestibulum quis neque nunc. Maecenas pharetra libero id
                        efficitur gravida. Aenean risus enim, coin quam in,
                        consequat nec lacus. Aenean faucibus venenatis aliquet.
                        Sed nulla quam, vehicula ut libe tpat quam. Phasellus
                        semper vitae tellus sit amet scelerisque
                      </p>
                    </div>
                  </div>
                </div>
                {/*
                 */}
                <div className="accordion-item inflanar-accordion__single  mg-top-20">
                  <h2 className="accordion-header" id="inflanart-4">
                    <button
                      className="accordion-button collapsed inflanar-accordion__heading"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#ac-collapse4"
                    >
                      Can you rovide documentation to implement?
                    </button>
                  </h2>
                  <div
                    id="ac-collapse4"
                    className="accordion-collapse collapse "
                    data-bs-parent="#inflanar-accordion"
                  >
                    <div className="accordion-body inflanar-accordion__body">
                      <p>
                        Vestibulum quis neque nunc. Maecenas pharetra libero id
                        efficitur gravida. Aenean risus enim, coin quam in,
                        consequat nec lacus. Aenean faucibus venenatis aliquet.
                        Sed nulla quam, vehicula ut libe tpat quam. Phasellus
                        semper vitae tellus sit amet scelerisque
                      </p>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- This snippet uses Font Awesome 5 Free as a dependency. You can download it at fontawesome.io! --> */}

      <div className="footer-cta inflanar-bg-cover section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-cta__inner inflanar-bg-cover  inflanar-section-shape3">
                <div className="footer-cta__content">
                  <h3 className="footer-cta__title">
                    Let influencers do the heavy lifting for your marketing
                    campaign
                  </h3>
                  <a
                    href="register.html"
                    className="inflanar-btn inflanar-btn__big"
                  >
                    <span>Join as Influencer</span>
                  </a>
                </div>
                <div className="footer-cta__img">
                  <img
                    alt="#"
                    src="assets/uploads/website-images/provider-joining-image-2023-09-06-06-56-12-3623.webp"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer-area p-relative">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-top-inner pd-top-30 pd-btm-100">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    {/*  */}
                    <div className="footer-about-widget">
                      <div className="footer-logo inflanar-header__logo">
                        <a className="logo" href="index.html">
                          <img
                            alt="#"
                            src="assets/uploads/website-images/logo-2023-08-22-09-14-55-4248.png"
                          />
                        </a>
                      </div>
                      <h4 className="footer-about-widget__title">
                        Want to Create Something Great Together?
                      </h4>
                      <div className="footer-get-touch">
                        <p>
                          Get in Touch..{" "}
                          <a href="mailto:user@gmail.com">sales@brandfluencers.in</a>
                        </p>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    {/*  */}
                    <div className="single-widget footer-useful-links">
                      <h3 className="widget-title">Quick Links</h3>
                      <ul className="footer__list list-none">
                        <li>
                          <a href="about-us.html">About Us</a>
                        </li>
                        <li>
                          <a href="services.html">Our Services</a>
                        </li>
                        {/* <li>
                          <a href="influencers.html">Our Influencers</a>
                        </li> */}
                        <li>
                          <a href="contact-us.html">Contact Us</a>
                        </li>
                        <li>
                          <a href="terms-conditions.html">
                            Terms And Conditions
                          </a>
                        </li>
                        <li>
                          <a href="privacy-policy.html">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="faq.html">FAQ</a>
                        </li>
                        <li>
                          <a href="login.html">My Profile</a>
                        </li>
                        <li>
                          <a href="login.html">Change Password</a>
                        </li>
                      </ul>
                    </div>
                    {/*  */}
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    {/*  */}
                    <div className="single-widget footer-contact">
                      <h3 className="widget-title">Working Hour</h3>
                      <div className="f-contact__form-top">
                        <ul className="f-contact-list list-none">
                          <li>Mon - Fri: 9.00am - 7.00pm</li>
                        </ul>
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="single-widget footer-contact mg-top-30">
                      <h3 className="widget-title">Our Location</h3>
                      <div className="f-contact__form-top">
                        <p>
                          202, Daksha Imperia, Indira Nagar, Nashik.
                        </p>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright__inner">
                  {/*  */}
                  <p className="copyright-text">
                    Copyright 2023, QuomodoSoft. All Rights Reserved.
                  </p>
                  {/*  */}
                  <ul className="inflanar-social inflanar-social__v2">
                    <li>
                      <a href="https://www.twitter.com/">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.behance.com/">
                        <i className="fa-brands fa-behance"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.linkedin.com/">
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                  </ul>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </footer>
    </>
  );
}

export default Homepagecomponent;
