function HeaderComponent() {
    return ( 
<>

		
{/* <!-- Mobile Menu Modal --> */}
		<div className="modal offcanvas-modal inflanar-mobile-menu fade" id="offcanvas-modal">
			<div className="modal-dialog offcanvas-dialog">
				<div className="modal-content">
					<div className="modal-header offcanvas-header">
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="fas fa-remove"></i>
						</button>
					</div>
					{/* <!-- offcanvas-logo-start --> */}
					 <div className="offcanvas-logo">
                        <a href="index.html"><img src="assets/uploads/website-images/brandfluencers-high-resolution-logo.png" alt="#"/></a>
					</div>
					{/* <!-- offcanvas-logo-end -->
					<!-- offcanvas-menu start --> */}
					<nav id="offcanvas-menu" className="offcanvas-menu">
						{/* <!-- Main Menu --> */}
						<ul className="nav-menu menu navigation list-none">

                                                        <li className="menu-item-has-children active"><a href="index1a93.html?theme=one">Home</a>
                                <ul className="sub-menu">
                                    <li><a href="index1a93.html?theme=one">Homepage V1</a></li>
                                    <li><a href="index924b.html?theme=two">Homepage V2</a></li>
                                    <li><a href="indexad8b.html?theme=three">Homepage V3</a></li>
                                </ul>
                            </li>
                            
							<li><a href="about-us.html">About Us</a></li>

							<li><a href="influencers.html">Influencers</a></li>

                            <li><a href="services.html">Services</a></li>

                            <li className="menu-item-has-children"><a href="index.html">Pages</a>
                                <ul className="sub-menu">
                                    <li><a href="blogs.html">Our Blogs</a></li>
                                    <li><a href="faq.html">FAQ</a></li>
                                    <li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
                                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                                                            <li><a href="page/custom-page-one.html">Custom Page One</a></li>
                                                                            <li><a href="page/custom-page-two.html">Custom Page Two</a></li>
                                    
                                </ul>
                            </li>

							<li><a href="contact-us.html">Contact</a></li>
						</ul>
						{/* <!-- End Main Menu --> */}
					</nav>
					{/* <!-- offcanvas-menu end --> */}

				
			</div>
		</div>
	</div>
		{/* <!-- End Mobile Menu Modal -->

		<!-- Header --> */}
		<header id="active-sticky" className="inflanar-header  ">
			<div className="inflanar-header__middle">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-12">
							<div className="inflanar-header__inside">
								<div className="inflanar-header__group">
									<div className="inflanar-header__logo">
										<a href="index.html"><img src="assets/uploads/website-images/brandfluencers-high-resolution-logo.png" alt="#" /></a>
									</div>
									<div className="inflanar-header__menu">
										<div className="navbar">
											<div className="nav-item">
												{/* <!-- Main Menu --> */}
												<ul className="nav-menu menu navigation list-none">
                                                    													<li className="menu-item-has-children"><a href="index1a93.html?theme=one">Home</a>
														<ul className="sub-menu">
															<li><a href="index1a93.html?theme=one">Homepage V1</a></li>
															<li><a href="index924b.html?theme=two">Homepage V2</a></li>
															<li><a href="indexad8b.html?theme=three">Homepage V3</a></li>
														</ul>
													</li>
                                                    


													<li><a href="influencers.html">Influencers</a></li>

													<li><a href="services.html">Services</a></li>

													<li className="menu-item-has-children"><a href="index.html">Pages</a>
														<ul className="sub-menu">

                                                            <li><a href="about-us.html">About Us</a></li>

															<li><a href="blogs.html">Our Blogs</a></li>
															<li><a href="faq.html">FAQ</a></li>
															<li><a href="terms-conditions.html">Terms &amp; Conditions</a></li>
															<li><a href="privacy-policy.html">Privacy Policy</a></li>
                                                            															    <li><a href="page/custom-page-one.html">Custom Page One</a></li>
                                                            															    <li><a href="page/custom-page-two.html">Custom Page Two</a></li>
                                                            
														</ul>
													</li>
													<li><a href="contact-us.html">Contact</a></li>
												</ul>
												{/* <!-- End Main Menu --> */}
											</div>
										</div>
									</div>
								</div>
								<button type="button" className="offcanvas-toggler" data-bs-toggle="modal" data-bs-target="#offcanvas-modal"><span className="line"></span><span className="line"></span><span className="line"></span>
								</button>
								<div className="inflanar-header__button h-with-lang-switch">


										

									


									<div className="qnav-btn-item">

                                                                                    <a href="login.html" className="inflanar-btn inflanar-btn--header"><span> Login</span></a>
                                        									</div>





								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		{/* <!-- End Header --> */}

</>



     );
}

export default HeaderComponent;