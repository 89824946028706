import React from 'react'
//import React, { useCallback } from 'react'

// import {
//   LoginSocialFacebook
// } from 'reactjs-social-login'

// import {
//   FacebookLoginButton
// } from 'react-social-login-buttons'
import HeaderComponent from './components/header/header'
import Homepagecomponent from './components/homepage/homepagecomponent'

// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes

const App = () => {

  // const onLoginStart = useCallback(() => {
  //   alert("login start");
  // }, []);


  return (
    <>

    <HeaderComponent />
      <Homepagecomponent/>
          {/* <LoginSocialFacebook
            isOnlyGetToken
            appId={'790244376282088'}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => {
              console.log('provider')
              console.log(provider)
              console.log('data')

              console.log(data)

            }}
            onReject={(err) => {
              console.log(err)
            }}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook> */}

          
      
    </>
  )
}

export default App

// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
